// type CssProfs<T> = { [P in keyof T]: React.CSSProperties };

// export const createStyles = (width: number, height: number) => {
//     const styles = create({
//         container: {
//             display: "flex",
//             flexDirection: "column",
//             width,
//             height,
//             overflow: "hidden",
//             overflowY: "scroll"
//         },
//         header: {
//             position: "sticky",
//             top: 0,
//             display: "flex",
//             width: "100%",
//             height: 40,
//             alignItems: "center",
//             padding: 8,
//             backgroundColor: "whitesmoke",
//             borderBottom: "1px solid #999"
//         },
//         detail: {
//             padding: 10
//         },
//         summary: {
//             padding: 10
//         },
//         images: {
//             display: "flex",
//             padding: 8,
//             justifyContent: "center",
//             flexWrap: "wrap"
//         },
//         image:{
//             maxWidth: width - 30,
//             maxHeight: 300,
//             objectFit: "contain",
//             borderRadius: "1px solid gray",
//             marginRight: 5,
//             marginBottom: 5
//         },
//         actions: {
//             display: "flex",
//             flexDirection: "column",
//             padding: 8
//         },
//         actionBtn: {
//             width: 120,
//             height: 26,
//             border: "1px solid #999",
//             borderRadius: 3,
//             margin: 5,
//             fontSize: "x-small",
//             fontWeight: "bold"
//         }
//     });
//     return styles;
// }

// function create<T extends CssProfs<T>>(styles: T | CssProfs<T>): T{
//     return styles as T;
// }

// export default createStyles;

type CssProfs<T> = { [P in keyof T]: React.CSSProperties };

export const createStyles = (width: number, height: number) => {
    const styles = create({
        container: {
            display: "flex",
            flexDirection: "column",
            width,
            height,
            overflow: "hidden",
            overflowY: "scroll"
        },
        header: {
            position: "sticky",
            top: 0,
            display: "flex",
            width: "100%",
            height: 40,
            alignItems: "center",
            padding: 8,
            backgroundColor: "whitesmoke",
            borderBottom: "1px solid #999"
        },
        detail: {
            padding: 10
        },
        summary: {
            padding: 10
        },
        images: {
            display: "flex",
            padding: 8,
            justifyContent: "center",
            flexWrap: "wrap"
        },
        image:{
            maxWidth: width - 30,
            height: 250,
            maxHeight: 250,
            objectFit: "contain",
            borderRadius: "1px solid gray",
            marginRight: 5,
            marginBottom: 5
        },
        actions: {
            display: "flex",
            flexDirection: "column",
            padding: 8
        },
        actionBtn: {
            width: 120,
            height: 26,
            border: "1px solid #999",
            borderRadius: 3,
            margin: 5,
            fontSize: "x-small",
            fontWeight: "bold"
        }
    });
    return styles;
}

function create<T extends CssProfs<T>>(styles: T | CssProfs<T>): T{
    return styles as T;
}

export default createStyles;