import React from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import ItemCell from '../../component/ItemCell/ItemCell';
import DetailWindow, { DetailWindowRef } from '../../module/DetailWindow';
import Header from '../../module/Header';
import SPHeader from '../../module/SPHeader';
import PlatformService from '../../service/PlatformService';
import YounashiService from '../../service/YounashiService';
import styles from './styles';

type Props = {} & RouteComponentProps<{ type: string }>;

const Works: React.FC<Props> = (props) => {

    const [dRef] = React.useState<DetailWindowRef>({});
    const [item, setItem] = React.useState<DevItem>();
    const type = props.match.params.type;

    const { isSP } = PlatformService();
    const { getData } = YounashiService();

    let items: DevItem[] = [];
    switch(type){
        case "works":
            items = getData().filter((i)=>i.type=="work"||i.type=="game");
            break;
        case "jobs":
            items = getData().filter((i)=>i.type=="job");
            break;
        case "other":
            items = getData().filter((i)=>i.type=="work");
            break;
        case "all":
            items = getData()
            break;        
        // default:
        //     items = getData()
        //     break;
    }

    const getTopText = () => {
        switch(type){
            case "works": return "過去に自分が開発した主なもの一覧です。";
            case "jobs": return "案件・仕事として行った主な開発の一覧です。公開できる(概要のみ公開可)もの一覧になります。";
        }
        return "";
    }

    const getActive = () => {
        switch(type){
            case "works": return "Works";
            case "jobs": return "Jobs";
        }
        return undefined
    }

    return(
        <article className="content" >
            {isSP() ? <SPHeader /> : <Header active={getActive()}/> }
            <Helmet>
                <title>{getActive() ?? ""} | YounashiPortfolio</title>
            </Helmet>
            <DetailWindow dRef={dRef} />
            <section style={styles.topContainer}>
                <p style={styles.titleText}>{type}</p>
                <p style={styles.topText}>{getTopText()}</p>
            </section>
            <section style={styles.items}>
                {items.map((i)=><ItemCell item={i} onClickDetail={(i)=>{ dRef.setVisible?.(true, i); }}/>)}
            </section>
        </article>
    )
}

export default Works;