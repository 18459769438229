import React, { RefObject } from 'react';
import VirtualPhoneIcon from '../../module/VirtualPhoneIcon';
// import styles from './styles';

export type VirtualPhoneApp = {
    name: string,
    icon: string,
    onclick?: ()=>void,
    widget?: (x: number, y: number)=> JSX.Element
}

type Props = {
    width: number,
    height: number,
    row?: number,
    column?: number,
    apps: VirtualPhoneApp[],
    style?: React.CSSProperties
}

const VirtualPhoneAppList: React.FC<Props> = ({ width, height, row, column, apps, style })=>{

    const r = row ?? 4;
    const c = column ?? 4;

    const x = width / r;
    const y = height / c;

    return(
        <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", width, ...style }}>
            {apps.map((a)=>a.widget ? a.widget(x, y) : (<VirtualPhoneIcon name={a.name} img={a.icon} onclick={a.onclick} width={x} height={y} />))}
        </div>
    )
}

export default VirtualPhoneAppList;