import PlatformService from "../../service/PlatformService";

type CssProfs<T> = { [P in keyof T]: React.CSSProperties };

export const createStyle = (isSP?: boolean) => {
    const styles = create({
        detailWindowContainer: {
            position: "fixed",
            display: "flex",
            flexDirection: "column",
            top: 0,
            left: 0,
            zIndex: 1,
            width: "100%",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,.8)",
            justifyContent: "center",
            alignItems: "center",
            transition: "all .2s",
            opacity: 0,
            visibility: "hidden",
            overflow: "hidden",
        },
        detailWindowContainerAnim: {
            visibility: "visible",
            opacity: 1
        },
        closeRow: {
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            minWidth: 320,
            width: 800,
            maxWidth: "calc(100% - 40px)",
            height: 50,
        },
        close: {
            height: 32,
            cursor: "pointer"
        },
        detailWindow: {
            minWidth: 320,
            width: 800,
            maxWidth: "calc(100% - 40px)",
            minHeight: 500,
            maxHeight: "calc(100% - 250px)",
            backgroundColor: "#eee",
            borderRadius: 12,
            padding: 10,
            overflowY: isSP ? "scroll" : "auto"
        },
        title: {
            fontSize: "x-large",
            fontWeight: "bold",
            margin: "8px 8px 0 8px"
        },
        type: {
            margin: "0 8px",
            color: "gray"
        },
        imageAria: {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            margin: "5px 0"
        },
        image: {
            objectFit: "contain",
            maxWidth: isSP ? "calc(100% - 50px)" : 500,
            height: 300,
            margin: 8
        },
        detail: {
            margin: 8,
            fontWeight: "bold"
        },
        summary: {
            margin: "0 8px"
        },
        urlContainer: {
            //display: "flex",
            margin: "15px 8px",
        },
        urlText: {
            color: "blue"
        }
    })
    return styles;
}

function create<T extends CssProfs<T>>(styles: T | CssProfs<T>): T{
    return styles as T;
}

export default createStyle;