type CssProfs<T> = { [P in keyof T]: React.CSSProperties };

const styles = create({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "white"
    },
    key: {
        fontSize: "x-large",
        fontWeight: "bold",
        margin: 12,
    },
    item: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: 8
    }
});

function create<T extends CssProfs<T>>(styles: T | CssProfs<T>): T{
    return styles as T;
}

export default styles;