import { url } from "inspector"
import { VirtualPhoneRef } from "../VirtualPhone/VirtualPhone"
import VirtualPhoneBrowser from "../VirtualPhoneBrowser/VirtualPhoneBrowser"
import { createStyles } from "./styles"

type Props = {
    item: DevItem
    height: number,
    width: number,
    vRef?: VirtualPhoneRef;
}

const ItemDetail: React.FC<Props> = ({ item, width, height, vRef }) => {

    const styles = createStyles(width, height)

    const openView = () => {
        if(item.url) vRef?.setScreen?.({
            screen: <VirtualPhoneBrowser url={item.url} height={height}/>,
            name: "brow"
        })
    }

    return(
        <article style={styles.container}>
            <section style={styles.header}>
                <p style={{fontWeight: "bold"}}>{item.name}</p>
            </section> 
            <section style={styles.detail}>
                <p>{item.detail}</p>
            </section>
            <section style={styles.images}>
                {item.images.map((i)=><img src={i} style={styles.image} />)}
            </section>
            <section style={styles.summary}>
                <p style={{fontWeight: "bold", fontSize: "small"}}>詳細</p>
                <p style={{fontSize: "small"}}>{item.summary}</p>
            </section>
            {item.url ? <section style={styles.actions}>
                <p style={{fontWeight: "bold", fontSize: "small", flex: 1}}>詳細リンク</p>
                <p style={{fontSize: "small"}}>{item.url.length < 30 ? item.url: item.url.slice(0,30)+"..."}</p>
                <nav style={{display: "flex", justifyContent: "center", margin: 5}}>
                    <button onClick={()=>{window.open(item.url)}} style={styles.actionBtn}>別のタブで開く(推奨)</button>
                    <button onClick={openView} style={styles.actionBtn}>この中で開く</button>
                </nav>
            </section> : <></>}
        </article>
    )
}

export default ItemDetail;