import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles';

const Header: React.FC = ()=>{

    return(
        <footer style={styles.footer}>
            <p style={{color: "whitesmoke"}}>© 2021 YounashiP.com</p>
        </footer>
    )
}

export default Header;