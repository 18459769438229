type CssProfs<T> = { [P in keyof T]: React.CSSProperties };

const styles = create({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "whitesmoke",
        maxWidth: 800,
    },
    iconLinkContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "space-around",
        flexWrap: "wrap",
    },
    title: {
        fontSize: "x-large",
        fontWeight: "bold",
        margin: "12px auto",
        color: "whitesmoke",
    },
    item: {
        display: "flex",
        width: 300,
        alignItems: "center",
        margin: 10
    },
    icon: {
        width: 80,
        height: 80,
        borderRadius: 10,
    },
    linkName: {
        fontSize: "large",
        fontWeight: "bold",
        margin: 8
    },
    otherContainer:{
        margin: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    otherTitle:{
        fontSize: "large",
        fontWeight: "bold",
    },
    otherSummary: {
        color: "gray",
        marginBottom: 10
    },
    otherLink: {
        margin: 5,
        color: "#ccc"
    }
});

function create<T extends CssProfs<T>>(styles: T | CssProfs<T>): T{
    return styles as T;
}

export default styles;