type CssProfs<T> = { [P in keyof T]: React.CSSProperties };

export const createStyles = (height: number) => {
    const styles = create({
        container: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            width: "100%",
            height: height,
            overflow: "hidden"
        },
        header: {
            display: "flex",
            height: 40,
            width: "100%",
            justifyContent: "center",
            alignItems: "center"
        },
        browser: {
            flex: 1,
            width: "100%",
        }
    });
    return styles;
}

function create<T extends CssProfs<T>>(styles: T | CssProfs<T>): T{
    return styles as T;
}

export default createStyles;