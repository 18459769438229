import React from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import ItemDetail from '../../component/ItemDetail/ItemDetail';
import VirtualPhone, { VirtualPhoneRef, VirtualPhoneScreen } from '../../component/VirtualPhone/VirtualPhone';
import VirtualPhoneAppList from '../../component/VirtualPhoneAppList';
import Header from '../../module/Header';
import SPHeader from '../../module/SPHeader';
import VirtualPhoneIcon from '../../module/VirtualPhoneIcon';
import PlatformService from '../../service/PlatformService';
import YounashiService from '../../service/YounashiService';
import styles from './styles';

type Props = {} & RouteComponentProps;

const Home: React.FC<Props> = (props) => {

    const { isSP } = PlatformService();
    const { getData } = YounashiService();
    const [vRef] = React.useState<VirtualPhoneRef>({}) 

    const deviceX = isSP() ? window.innerWidth - 5 : 350;
    const deviceY = isSP() ? (window.innerWidth - 5) / .56 : 700;
    const dispX = deviceX - 10;
    const dispY = deviceY - 120;

    const items = getData();

    const tab = (x: number, y: number, name: string) => (
        <div style={{width: x*4, height: y, display: "flex", justifyContent: "center", alignItems: "center"}}>
            <p style={{color: "whitesmoke", fontSize: "x-large"}}>{name}</p>
        </div>
    )

    const appDetail = (item: DevItem) => (
        vRef.setScreen?.({name: "", screen: <ItemDetail item={item} width={dispX} height={dispY} vRef={vRef}/>})
    )

    const screens: JSX.Element[] = [
        <VirtualPhoneAppList
            width={dispX} height={dispY}
            apps={[
                {icon: "", name: "", widget: (x, y)=> tab(x, y, "Works / Games") },
                ...items.filter((i)=>i.type=="work"||i.type=="game").map((i)=>({icon: i.icon, name: i.name, onclick: ()=>appDetail(i)}))
            ]}/>,
        <VirtualPhoneAppList
            width={dispX} height={dispY} 
            apps={[
                {icon: "", name: "", widget: (x, y)=> tab(x, y, "Jobs") },
                ...items.filter((i)=>i.type=="job").map((i)=>({icon: i.icon, name: i.name, onclick: ()=>appDetail(i)}))
            ]}/>,
        // <VirtualPhoneAppList
        //     width={dispX} height={dispY}
        //     apps={[
        //         {icon: "", name: "", widget: (x, y)=> tab(x, y, "Games") },
        //         ...items.filter((i)=>i.type=="game").map((i)=>({icon: i.icon, name: i.name, onclick: ()=>appDetail(i)}))
        //     ]}/>,
        <VirtualPhoneAppList
            width={dispX} height={dispY}
            apps={[
                {icon: "", name: "", widget: (x, y)=> tab(x, y, "Links") },
                ...items.filter((i)=>i.type=="link").map((i)=>({icon: i.icon, name: i.name, onclick: ()=>{ window.open(i.url) }}))
            ]}/>
    ] 
    const route: VirtualPhoneScreen[] = [
        
    ]

    return(
        <article className="content" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            {isSP() ? <SPHeader /> : <Header /> }
            <Helmet>
                <title>YPhone | YounashiPortfolio</title>
            </Helmet>
            <VirtualPhone 
                width={deviceX} height={deviceY} 
                screens={screens} 
                route={route} 
                vRef={vRef}
                wallpaper="img/3dcg-tokyo.png"
                style={{margin: "auto 0"}}
            />
        </article>
    )
}

export default Home;