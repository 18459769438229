type CssProfs<T> = { [P in keyof T]: React.CSSProperties };

const styles = create({
    header: {
        position: "relative",
        display: "flex",
        top: 0,
        left: 0,
        width: "100%",
        height: 80,
        borderBottom: "1px solid rgb(200,200,200)",
        zIndex: 10,
        alignItems: "center",
        backgroundColor: "black"
    },
    logoText: {
        color: "whitesmoke",
        fontSize: "large",
        fontWeight: "bold",
        margin: 15,
        cursor: "pointer"
    },
    nav: {
        display: "flex",
        flex: 1,
        justifyContent: "flex-end"
    },
    navItem: {
        display: "flex",
        width: 150,
        justifyContent: "center",
        alignItems: "center",
        color: "whitesmoke",
    }
})

function create<T extends CssProfs<T>>(styles: T | CssProfs<T>): T{
    return styles as T;
}

export default styles;