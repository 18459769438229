import React, { createRef } from "react";
import { createStyles } from "./styles"
import "./styles.css";

export type VirtualPhoneRef = {
    navigate?: (name: string)=>void
    setScreen?: (screen: VirtualPhoneScreen)=>void
}

export type VirtualPhoneScreen = {
    name: string,
    screen: JSX.Element,
    args?: unknown
}

type Props = {
    width: number,
    height: number,
    screens: JSX.Element[],
    route: VirtualPhoneScreen[],

    style?: React.CSSProperties
    vRef?: VirtualPhoneRef
    wallpaper?: string
}

const VirtualPhone: React.FC<Props> = ({ width, height, screens, route, vRef, wallpaper, style }) => {

    /// Screen Pad < 5* 2 ^ 50* 2
    const styles = createStyles(width, height);
    const [tmpScreen, setTmpScreen] = React.useState<JSX.Element>();
    const [disableScreen, setDisableScreen] = React.useState(false);
    const [activeRoute, setActiveRoute] = React.useState<VirtualPhoneScreen>();

    const [scrollX, setScrollX] = React.useState(0);
    const [time, setTime] = React.useState(new Date());

    const setRoute = (screen?: VirtualPhoneScreen)=>{
        console.log(screen, activeRoute)
        if(screen){
            if(activeRoute) {
                setTmpScreen(activeRoute.screen);
                //setDisableScreen(true);
                setActiveRoute(undefined);
                //setDisableScreen(false)

                setTimeout(() => {
                    setActiveRoute(screen)
                }, 100);
                return; 
            }
        }else{
            setTmpScreen(undefined);
        }
        setActiveRoute(screen)
    }

    const dispX = width - 10;

    React.useEffect(()=>{
        setTimeout(()=>setTime(new Date()), 5000)
    },[])

    if(vRef) {
        vRef.navigate = (name)=>{ setRoute(route.find((r)=>r.name==name)) }
        vRef.setScreen = (screen)=>{ setRoute(screen) }
    }


    const [scProps, setScProps] = React.useState<{
        ev: any, scrollTo: (()=>void) | null, mouseX: number, scrX: number
    }>({
        ev: null, scrollTo: null, mouseX: -1, scrX: -1
    })

    // let ev: any = null;
    // let scrollTo: (()=>void) | null = null;

    // let mouseX = -1;
    // let scrX = -1;
    console.log("RENDER")
    
    return(
        <article style={{...styles.phone, ...style}}>
            <section style={styles.display} >

                {wallpaper ? <img src={wallpaper} style={styles.wallpaper}/> : <></> }

                <section style={styles.statusBar}>
                    <p style={{fontSize: "small"}}>YPhone</p>
                    <p style={{flex: 1, fontSize: "small", textAlign: "end"}}>{`${("00"+time.getHours()).slice(-2)}:${("00"+time.getMinutes()).slice(-2)}`}</p>
                </section>

                <nav className="scrollView" style={styles.displayContent} onScroll={(e)=>{
                    // clearTimeout(ev);
                    // ev = setTimeout(()=>{
                    //     scrollTo?.()
                    // }, 80)

                    // scrX = e.currentTarget.scrollLeft;
                    clearTimeout(scProps.ev);
                    const ev = setTimeout(()=>{
                        //scrollTo?.()
                        scProps.scrollTo?.();
                    }, 80)

                    const scrX = e.currentTarget.scrollLeft;
                    setScProps({...scProps, ev, scrX})
                }} 
                onTouchStart={()=> 
                    //scrollTo = null 
                    setScProps({...scProps, scrollTo: null})
                }
                onTouchEnd={(e)=>{
                    const t = e.currentTarget;
                    // scrollTo = () => t.scrollTo({
                    //     left: Math.round(t.scrollLeft / dispX) * dispX ,
                    //     behavior: "smooth"
                    // })
                    // clearTimeout(ev);
                    // ev = setTimeout(()=>{
                    //     scrollTo?.()
                    // }, 100)
                    const scrollTo = () => t.scrollTo({
                        left: Math.round(t.scrollLeft / dispX) * dispX ,
                        behavior: "smooth"
                    })
                    clearTimeout(scProps.ev);
                    const ev = setTimeout(()=>{
                        scrollTo?.()
                    }, 100)
                    setScProps({...scProps, ev, scrollTo})
                }} 

                onMouseDown={(e)=> {
                    console.log("DOWN", e.clientX)
                    //mouseX = e.clientX
                    setScProps({...scProps, mouseX: e.clientX})
                }}

                onTouchMove={(e)=>{
                    console.log("CANELS")
                    // e.currentTarget.scrollTo({
                    //     left: Math.round(e.currentTarget.scrollLeft / width) * width ,
                    //     behavior: "smooth"
                    // })
                }}

                onMouseMove={(e)=>{
                    // if(mouseX < 0) return;
                    // const dx = e.clientX - mouseX;   
                }}

                onMouseUp={(e)=>{
                    console.log("UP")
                    if(scProps.mouseX < 0) return;
                    const t = e.currentTarget;
                    const dx = scProps.mouseX - e.clientX;
                    const mouseX = -1;
                    console.log("dx", dx)
                    if(dx < -40){
                        console.log("L")
                        if(t.scrollLeft > 0) t.scrollTo({left: Math.floor((t.scrollLeft - 1) / dispX) * dispX, behavior: "smooth" })
                    }else if(dx > 40 ){
                        console.log("R")
                        t.scrollTo({left: Math.ceil((t.scrollLeft + 1 ) / dispX) * dispX, behavior: "smooth" })
                    }
                    setScProps({...scProps, mouseX})
                }}

                onMouseLeave={(e)=>{
                    console.log("OUT")
                    setScProps({...scProps, mouseX: -1})
                }}
                
                >
                {screens.map((s)=>(
                    <div style={styles.screen}>
                        {s}
                    </div>
                ))}

                {tmpScreen ? <section className="tmpscr" style={{...styles.cover, ...styles.coverVisible, zIndex: 0}}>{tmpScreen}</section> : <></>}

                <section style={activeRoute ? {...styles.cover, ...styles.coverVisible} : styles.cover}>
                    {activeRoute?.screen ?? <></>}
                </section> 

                </nav>
                
            </section>

            <input type="button" style={styles.homeBtn} onClick={()=>{setRoute(
                undefined
            )}}/>

        </article>
    )
}

export default VirtualPhone;