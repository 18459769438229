import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Header from './module/Header';
import Footer from './module/Footer';
import Home from './screen/Home';
import Works from './screen/Works';
import Links from './screen/Links';
import Others from './screen/Others';
import Profile from './screen/Profile';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter >
      <Switch>
        <Route path="/profile" component={Profile} />
        <Route path="/other" component={Others} />
        <Route path="/link" component={Links} />
        <Route path="/:type" component={Works} />
        <Route path="/" component={Home} />
      </Switch>
    <Footer />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
