type CssProfs<T> = { [P in keyof T]: React.CSSProperties };

// type Props = {
//     width: number
//     height: number
// }

export const createStyles = (width: number, height: number) => {
    const styles = create({
        phone: {
            display: "flex",
            position: "relative",
            backgroundColor: "#333",
            borderRadius: 16,
            width,
            height,
            cursor: "pointer"
        },
        display: {
            position: "relative",
            display: "flex",
            flex: 1,
            backgroundColor: "#666",
            borderRadius: 2,
            margin: "50px 5px",
            overflow: "hidden"
        },
        displayContent:{
            display: "flex",
            overflow: "hidden",
            overflowX: "scroll",
            msOverflowStyle: "none",
            marginTop: 20,
        },
        homeBtn: {
            position: "absolute",
            bottom: 0, 
            width: 40,
            height: 40,
            backgroundColor: "black",
            borderRadius: 20,
            right: 0,
            left: 0,
            margin: "5px auto"
        },
        screen: {
            position: "relative",
            minHeight: height - 120, // -Pad
            minWidth: width - 10, // -Pad
        },
        statusBar: {
            position: "absolute",
            display: "flex",
            alignItems: "center",
            top: 0,
            left: 0,
            width: "calc(100% - 10px)",
            height: 20,
            padding: "0 5px",
            backgroundColor: "#111",
            color: "whitesmoke"
        },
        cover: {
            position: "absolute",
            minHeight: height - 120, // -Pad
            minWidth: width - 10, // -Pad
            top: height - 120, // -Pad       
            left: 0,
            transition: "all .3s",
            zIndex: 1,
            backgroundColor: "whitesmoke",
            opacity: 0
        },
        coverVisible: {
            top: 20,
            opacity: 1
        },
        wallpaper: {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
        }
    });
    return styles;
}

function create<T extends CssProfs<T>>(styles: T | CssProfs<T>): T{
    return styles as T;
}

export default createStyles;