type CssProfs<T> = { [P in keyof T]: React.CSSProperties };

const width = window.innerWidth > 420 ? 400 : window.innerWidth - 20

const styles = create({
    cell: {
        display: "flex",
        position: "relative",
        width,
        height: width* .75,
        margin: 8,
        backgroundColor: "#111",
        borderRadius: 10,
        boxShadow: "0px 0px 4px 2px rgba(200,200,200,.5)",
        color: "white",
    },
    row: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
    },
    title: {
        fontWeight: "bold",
        margin: 12,
        fontSize: "large"
    },
    type: {
        padding: "0 8px",
        fontSize: "small",
        color: "gray"
    },
    detail: {
        padding: 8,
        fontSize: "small"
    },
    img: {
        // position: "absolute",
        // top: 10,
        // right: 10,
        // width: width/2,
        padding: 10,
        width: "calc(100% - 20px)",
        height: "calc(100% - 20px)",
        objectFit: "contain",
    },
    link: {
        margin: "auto 8px 8px auto",
        borderRadius: 5,
        backgroundColor: "whitesmoke",
        color: "gray",
        fontWeight: "bold",
        padding: "5px 8px",
        cursor: "pointer"
    }
});

function create<T extends CssProfs<T>>(styles: T | CssProfs<T>): T{
    return styles as T;
}

export default styles;