type CssProfs<T> = { [P in keyof T]: React.CSSProperties };

const styles = create({
    header: {
        position: "fixed",
        display: "flex",
        top: 0,
        left: 0,
        width: "100%",
        height: 50,
        borderBottom: "1px solid rgb(200,200,200)",
        zIndex: 10,
        alignItems: "center",
        backgroundColor: "black"
    },
    headerSpace: {
        height: 50,
        width: "100%"
    },
    logoText: {
        color: "whitesmoke",
        fontSize: "large",
        fontWeight: "bold",
        margin: 15
    },
    hamburger: {
        position: "absolute",
        top: 10,
        right: 10,
        height: 30,
        width: 30
    },
    nav: {
        position: "fixed",
        top: 50 + 2,
        right: 0,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "black",
        transition: "all .5s",
        width: 0,
        maxHeight: 0,
    },
    navAnim: {
        width: 200,
        overflow: "hidden",
        maxHeight: 300
    },
    navItem: {
        display: "flex",
        width: 200,
        height: 50,
        justifyContent: "center",
        alignItems: "center",
        color: "whitesmoke",
    }
})

function create<T extends CssProfs<T>>(styles: T | CssProfs<T>): T{
    return styles as T;
}

export default styles;