const PlatformService = () => {
    
    const isSP = () => {
        console.log("isSP?")
        if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
            console.log("T")
            return true;
        } else {            
            console.log("F")
            return false;
        }
    }
    
    return{
        isSP
    }
}

export default PlatformService;