import React from 'react';
// import styles from './styles';

type Props = {
    name: string,
    img: string,
    width: number,
    height: number,
    onclick?: ()=> void
}

const VirtualPhoneIcon: React.FC<Props> = ({ name, img, width, height, onclick })=>{

    const [isPress, setIsPress] = React.useState(false);

    return(
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", width, height, borderRadius: 8, opacity: isPress ? .6 : 1}} 
            onClick={()=>onclick?.()} onTouchStart={()=>setIsPress(true)} onTouchEnd={()=>setIsPress(false)} onTouchCancel={()=>setIsPress(false)}>
            <img src={img} style={{width: width*.7, height: width*.7, objectFit: "cover", borderRadius: 8, overflow: "hidden"}}/>
            <p style={{fontSize: ".75em", color: "whitesmoke", marginTop: 3}}>{name.length<12 ? name : name.slice(0,10)+"..."}</p>
        </div>
    )
}

export default VirtualPhoneIcon;