type CssProfs<T> = { [P in keyof T]: React.CSSProperties };

export const createStyle = (isSP: boolean) => {
    
    const styles = create({
        container: {
            display: "flex",
            alignItems: "center",
            color: "whitesmoke"
        },
        profile: {
            position: "relative",
            width: isSP ? undefined : 800,
            minWidth: 320,
            margin: 20
        },
        pUnderLine: {
            margin: "8px 0",
            height: 1,
            width: 0,
            borderBottom: "1px solid white",
            transition: "all .3s"
        },
        pUnderLineAnim: {
            width: "100%" 
        },
        pRightLine: {
            position: "absolute",
            bottom: 0,
            right: 20,
            borderRight: "1px solid white",
            width: 1,
            height: 0,
            transition: "all .3s"
        },
        pRightLineAnim: {
            height: "calc(100% - 20px)"
        },
        title: {
            fontSize: "xx-large",
            fontWeight: "bold"
        },
        pContainer: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around"
        },
        profImg: {
            width: 200,
            height: 200,
            objectFit: "cover",
            margin: 20
        },
        profTexts: {
            flexBasis: 1,
            minWidth: 300,
            paddingRight: 20
        },
        moreBtn: {
            maxHeight: 40,
            width: 200,
            textAlign: "center",
            padding: 8,
            margin: "5px 10px",
            color: "#ddd",
            borderBottom: "2px solid gray",
            fontWeight: "bold",
            fontSize: "large",
            overflow: "hidden",
            whiteSpace: "nowrap",
            transition: "all .3s",
            opacity: 0.5,
            cursor: "pointer",
        },
        moreBtnAnim: {
            opacity: 1
        }

    });

    return styles;
}

function create<T extends CssProfs<T>>(styles: T | CssProfs<T>): T{
    return styles as T;
}

export default createStyle;