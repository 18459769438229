const API_PATH = "/api"

type APIResult<T> = {
    status: "success" | "error"
    data: T
} 

export const data: DevItem[] = [
    {type: "work", name: "洋梨日記", icon: "https://younaship.com/Nashi.png", 
        images: ["https://younaship.com/wp-content/uploads/2021/02/image-4-1024x617.png"], 
        detail: "自分のメインのウェブサイト", 
        summary: "2017年から運営している自分の4代目ウェブサイト。月間PV数は1万程度。運営中Webサイトの中では一番更新している(つもり)", 
        url: "https://younaship.com/",
        apptype: "website"
    },
    {type: "work", name: "UPnP Tool", icon: "/appicon/upnp.png", 
        images: ["https://younaship.com/wp-content/uploads/2019/04/Screenshot_20190214-164417-553x1024.jpg"], 
        detail: "ポート開放を簡単に行うモバイル向けアプリ", 
        summary: "UPnPを使ってポート開放をするアプリを開発およびストアで公開。作るにあたっての解析・分析など情報は https://younaship.com/tag/upnp/ あたりで公開中。", 
        url: "https://play.google.com/store/apps/details?id=com.younaship.UPnP_PortTool",
        apptype: "mobile application"
    },
    {type: "work", name: "@Collect", icon: "/appicon/atcol.png", 
        images: ["http://my.younaship.com/img/at-collect_sc.png", "http://my.younaship.com/img/at-collect_sc2.png"], 
        detail: "匿名の質問をインスタグラムで募集・聞けるサービス", 
        summary: "「Box Fresh」の流行にのってスピード開発。2,3日で作った。3ヶ月程運営したが、維持費よりプラスになる見込が立たずサービス停止。", 
        apptype: "web application"
    },
    {type: "work", name: "CCollect", icon: "/appicon/ccol.png", 
        images: ["http://my.younaship.com/img/c-collect_sc.png"], 
        detail: "クリエイター(Youtuber)が紹介した商品をまとめたWebサイト", 
        summary: "クリエイターが紹介した商品をマークしたり、お気に入りのクリエイターが紹介している商品を常にチェックする事ができるサービス。AD系を通す為に度々変更(ブログベースにする等)したが、通らなかった為サービス中止した。", 
        url: "https://g-cc.jp/",
        apptype: "web application"
    },
    {type: "work", name: "シンプル時間割", icon: "/appicon/time.png", 
        images: ["https://younaship.com/wp-content/uploads/2019/03/Header_TimeTable_APP_s.png","https://younaship.com/wp-content/uploads/2019/03/Screenshot_20190228-201152.jpg","https://younaship.com/wp-content/uploads/2019/03/Screenshot_20190228-201305_.jpg"], 
        detail: "大学生のための使いやすい”シンプル”な時間割アプリ", 
        summary: "4月の新学期に向け、新学期が始まる1か月前の3月上旬公開を目標に開発を始めたアプリ。", 
        url: "https://play.google.com/store/apps/details?id=com.younaship.timetable",
        apptype: "mobile application"
    },
    {type: "work", name: "BookInfo", icon: "/appicon/binfo.png", 
        images: ["https://younaship.com/wp-content/uploads/2021/02/image-3.png"], 
        detail: "[開発中] 本の新刊情報をチェックしたり、好きな本を集めて本棚を作ってシェアしたり出来るサービス", 
        summary: "開発中のサービス。Googleにインデックス登録してもらうため、事前に基本機能のみで公開済み。", 
        url: "https://bookinfo.jp",
        apptype: "web application"
    },
    // {type: "work", name: "", icon: "/appicon/", 
    //     images: ["http://my.younaship.com/img/at-collect_sc.png"], 
    //     detail: "", 
    //     summary: "", 
    //     url: ""
    // },

    {type: "job", name: "HAMONI公式", icon: "/appicon/whamo.png", 
        images: ["https://younaship.com/wp-content/uploads/2021/02/image-9-1024x618.png"], 
        detail: "株式会社HAMONIの公式サイト", 
        summary: "株式会社HAMONIの公式サイトの作成をしました。3Dも組み合わせて(PC表示時)いい感じのホームページを作成。", 
        apptype: "website",
        url: "https://hamoni.jp/"
    },
    {type: "job", name: "HAMONI", icon: "/appicon/hamoni.png", 
        images: ["https://younaship.com/wp-content/uploads/2021/02/image-12-576x1024.png","https://younaship.com/wp-content/uploads/2021/02/image-13-576x1024.png","https://younaship.com/wp-content/uploads/2021/02/image-14-498x1024.png","https://younaship.com/wp-content/uploads/2021/02/image-15-498x1024.png"], 
        detail: "「HAMONI」のネイティブアプリ、Webアプリ、APIサーバー等を作成。", 
        summary: "株式会社HAMONIの自社アプリ「HAMONI」のネイティブアプリ、Webアプリ、APIサーバー等を作成。UI/UXデザインはメンバーが作成。ネイティブアプリは他メンバーにも協力してもらい開発。※新型コロナウィルスが収まり(目途が付き)次第リリース予定。", 
        apptype: "website",
        url: "https://app.hamoni.jp/"
    },
    {type: "job", name: "グループ", icon: "/appicon/web.png", 
        images: ["https://younaship.com/wp-content/uploads/2021/02/image-7-1024x657.png"], 
        detail: "KPopアイドルグループのWebサイト作成", 
        summary: "新KPopアイドルグループのWebサイト作成の案件", 
        apptype: "website"
    },
    {type: "job", name: "修理業者", icon: "/appicon/web.png", 
        images: ["https://younaship.com/wp-content/uploads/2021/02/image-8-1024x623.png"], 
        detail: "修理業者のWebサイト作成・運用", 
        summary: "修理業者のWebサイト作成、広告運用", 
        apptype: "website"
    },


    {type: "game", name: "僕の甲子園", icon: "/appicon/boku.png", 
        images: ["https://younaship.com/wp-content/uploads/2019/04/Screen_Bokuno_a0.png", "https://younaship.com/wp-content/uploads/2019/04/Screen_Bokuno_a1.png"], 
        detail: "複数のミニゲームを攻略し甲子園へ主人公を導くゲーム", 
        summary: "友達と複数人で開発。プログラミングは自分が行い、友達にストーリーや絵を作ってもらった。学園祭に展示したりした。", 
        url: ""
    },
    {type: "game", name: "Dream Block", icon: "/appicon/block.png", 
        images: ["https://younaship.com/wp-content/uploads/2019/04/ScreenShot_Dream1.png", "https://younaship.com/wp-content/uploads/2019/04/Screen_DreamBlocker_goal.png", "https://younaship.com/wp-content/uploads/2021/02/image.png"], 
        detail: "床に移動ブロックを設置してゴールへと導くゲーム", 
        summary: "友達と複数人で開発。プログラミング・モデリングは自分が行い、友達に絵を作ってもらった。", 
    },
    {type: "game", name: "冒険クラフター", icon: "/appicon/outis.png", 
        images: ["https://younaship.com/wp-content/uploads/2019/04/ScreenShot_Isrand1.png", "https://younaship.com/wp-content/uploads/2019/04/ScreenShot_Isrand2.png"], 
        detail: "アイテムを集め、アイテムを作り、敵を倒して島を攻略していくゲーム", 
        summary: "モデリング、絵から自分で作ってみた作品。", 
    },
    {type: "game", name: "VStudyApp", icon: "/appicon/vstudy.png", 
        images: ["https://younaship.com/wp-content/uploads/2021/02/image-2.png", "https://younaship.com/wp-content/uploads/2021/02/image-1.png"], 
        detail: "クイズで学習しながらキャラを強化し、友達と対戦出来るゲーム", 
        summary: "ゼミの発表の為にチームで作ったゲーム。絵や問題は友達が作成。自分はプログラミングを担当。8人までの友達とオンライン対戦が出来る。", 
        url: ""
    },
    // {type: "game", name: "", icon: "/appicon/", 
    //     images: [""], 
    //     detail: "", 
    //     summary: "", 
    //     url: ""
    // },
    {type: "link", name: "洋梨日記", icon: "/appicon/younaship.png", 
        images: [""], 
        detail: "", 
        summary: "", 
        url: "https://younaship.com/"
    },
    {type: "link", name: "GitHub", icon: "/appicon/github.png", 
        images: [""], 
        detail: "", 
        summary: "", 
        url: "https://github.com/younaship"
    },
    {type: "link", name: "Qiita", icon: "/appicon/qiita.png", 
        images: [""], 
        detail: "", 
        summary: "", 
        url: "https://qiita.com/younaship"
    },
    {type: "link", name: "LinkedIn", icon: "/appicon/linkedin.png", 
        images: [""], 
        detail: "", 
        summary: "", 
        url: "https://www.linkedin.com/in/younaship/"
    },
]

const YounashiService = () => {

    const getData = () => {
        // const r = await fetch(API_PATH+"/latest").catch(()=>null);
        // if(r?.ok){
        //     const j = await r.json();
        //     return j as APIResult<DevItem[]>;
        // }
        return data;
    }

    return {
        getData,
    }
}

export default YounashiService;