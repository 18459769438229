type CssProfs<T> = { [P in keyof T]: React.CSSProperties };

const styles = create({
    items: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around"
    },
    topContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "whitesmoke",
        padding: 5,
    },
    titleText: {
        fontSize: "x-large",
        fontWeight: "bold",
        borderBottom: "1px solid white",
        padding: "3px 10px"
    },
    topText: {
        margin: 5,
        fontWeight: "bold",
        color: "gray"
    }
});

function create<T extends CssProfs<T>>(styles: T | CssProfs<T>): T{
    return styles as T;
}

export default styles;