import React from 'react';
import { Link } from 'react-router-dom';
import PlatformService from '../../service/PlatformService';
import { createStyle } from './styles';

export type DetailWindowRef = {
    setItem?: (item: DevItem) => void
    setVisible?: (state: boolean, item?: DevItem) => void
}

type Props = {
    dRef?: DetailWindowRef
}

const DetailWindow: React.FC<Props> = ({ dRef })=>{

    const { isSP } = PlatformService();
    const styles = createStyle(isSP());
    const [isVisible, setIsVisible] = React.useState(false);
    const [item, setItem] = React.useState<DevItem>();

    if(dRef){
        dRef.setItem = (i) => setItem(i);
        dRef.setVisible = (s, i) => {
            setItem(i);
            setIsVisible(s);
        }
    }

    return(
        <div style={isVisible ? {...styles.detailWindowContainer, ...styles.detailWindowContainerAnim } : styles.detailWindowContainer }>
            <nav style={styles.closeRow}><img src="/img/cross.png" style={styles.close} onClick={()=>setIsVisible(false)}/></nav>
            <article style={styles.detailWindow}>
                <p style={styles.title}>{item?.name}</p>
                <p style={styles.type}>{item?.apptype}</p>
                <section style={styles.imageAria}>
                    {item?.images.map((i)=><img src={i} style={styles.image}/>)}
                </section>
                <section>
                    <p style={styles.detail}>{item?.detail}</p>
                    <p style={styles.summary}>{item?.summary}</p>
                </section>
                {item?.url ? <section style={styles.urlContainer}>
                    <p style={{fontWeight: "bold", color: "gray"}}>詳細リンク</p>
                    <a href={item.url} style={styles.urlText} >{item.url.length < 50 ? item.url : item.url.slice(0, 50)+"..." }</a>
                </section> : <></>}
            </article>
        </div>
    )
}

export default DetailWindow;