import React from "react";
import { VirtualPhoneRef } from "../VirtualPhone/VirtualPhone"
import { createStyles } from "./styles"

type Props = {
    height: number,
    url: string,
    vRef?: VirtualPhoneRef;
}

const VirtualPhoneBrowser: React.FC<Props> = ({ height, url, vRef }) => {

    // const [uri, setUri] = React.useState<null|string>(url);
    const styles = createStyles(height)

    return(
        <article style={styles.container}>
            <section style={styles.header}>
                {/* <p style={{fontWeight: "bold", fontSize: "small"}}>{url}</p> */}
                <input type="text" value={url} style={{fontWeight: "bold", fontSize: "small", flex: 1, padding: 5, margin: 5,}} disabled />
            </section> 
            <p style={{fontSize: "x-small"}}>※サイトによっては動作しない事があります。<a style={{color: "blue"}} href={url} target="_blank">新しいタブ</a>で開いてください。</p>
            <iframe style={styles.browser} src={url}/>
        </article>
    )
}

export default VirtualPhoneBrowser;