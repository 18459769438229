import styles from "./styles"

type Props = {
    item: DevItem,
    onClickDetail: (item: DevItem) => void
    // title: string,
    // img?: string
}

const Cell: React.FC<Props> = ({ item, onClickDetail }) => {
    return(
        <div style={styles.cell}>
            <section style={styles.row}>
                <p style={styles.title}>{item.name}</p>
                <p style={styles.type}>{item.apptype}</p>
                <p style={styles.detail}>{item.detail}</p>
                <button style={styles.link} onClick={()=>onClickDetail?.(item)}>詳細を開く</button>
            </section>
            <section style={styles.row}>
                <img src={item.images[0]} style={styles.img}/>
            </section>
            {/* <img src={img} style={styles.img}/> */}
        </div>
    )
}

export default Cell;