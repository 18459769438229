type CssProfs<T> = { [P in keyof T]: React.CSSProperties };

const styles = create({
    footer: {
        display: "flex",
        width: "100%",
        height: 50,
        alignItems: 'center',
        justifyContent: "center",
        backgroundColor: "black"
    }
})

function create<T extends CssProfs<T>>(styles: T | CssProfs<T>): T{
    return styles as T;
}

export default styles;