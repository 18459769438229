import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, RouteComponentProps } from 'react-router-dom';
import ItemDetail from '../../component/ItemDetail/ItemDetail';
import VirtualPhone, { VirtualPhoneRef, VirtualPhoneScreen } from '../../component/VirtualPhone/VirtualPhone';
import VirtualPhoneAppList from '../../component/VirtualPhoneAppList';
import Header from '../../module/Header';
import SPHeader from '../../module/SPHeader';
import VirtualPhoneIcon from '../../module/VirtualPhoneIcon';
import PlatformService from '../../service/PlatformService';
import YounashiService from '../../service/YounashiService';
import { createStyle } from './styles';

type Props = {} & RouteComponentProps;

const Profile: React.FC<Props> = (props) => {

    const { isSP } = PlatformService();
    const styles = createStyle(isSP());

    const [isLoaded, setIsLoaded] = React.useState<boolean>();

    React.useEffect(()=>{
        setIsLoaded(true);
    },[]);

    return(
        <article className="content" style={styles.container}>
            {isSP() ? <SPHeader /> : <Header active="Profile"/>}
            <Helmet>
                <title>Sugimoto Takeru - Profile | Portfolio</title>
            </Helmet>
            <section style={styles.profile}>
                <p style={styles.title}>Profile</p>
                <div style={ !isLoaded ? styles.pUnderLine : {...styles.pUnderLine, ...styles.pUnderLineAnim}} />
                <div style={ !isLoaded ? styles.pRightLine : {...styles.pRightLine, ...styles.pRightLineAnim}} />

                <div style={styles.pContainer}>
                    <img src="http://potf.younaship.com/img/prof.jpg" style={styles.profImg} />
                    <div style={styles.profTexts}>
                        <h2>Sugimoto Takeru</h2>
                        <p>はじめまして！杉本 健です。</p>
                        <p>モノづくりが昔から好きで色々と作ってきました。なので色々と作れます。(^v^)</p>
                        <p>主なスキルはプログラミングと3DCGです。</p>
                    </div>
                </div>
            </section>
            <section style={styles.profile}>
                <p style={styles.title}>More</p>
                <div style={styles.pContainer}>
                    <p style={{fontSize: "small", color: "lightgray", margin: 8}}>主な開発履歴をこちらで見ることが出来ます。</p>
                    <p style={ !isLoaded ? styles.moreBtn : {...styles.moreBtn, ...styles.moreBtnAnim}}>
                        <Link to="/">YPhoneで見る</Link>
                    </p>
                    <p style={ !isLoaded ? styles.moreBtn : {...styles.moreBtn, ...styles.moreBtnAnim}}> 
                        <Link to="/works">通常表示で見る</Link>
                    </p>
                </div>
            </section>
            <section style={styles.profile}>
                <p style={styles.title}>Skill</p>
                <p style={{fontSize: "small", color: "lightgray", margin: 8}}>自分のスキル一覧です。(触れていたおおよその年数)</p>
                <div style={{display: "flex", flexWrap: "wrap"}}> 
                    <ul>
                        <li>JavaSctipt/TypeScript (2year)</li>
                        <li>C# (2year)</li>
                        <li>Java (Android Java) (1year)</li>
                        <li>PHP (0.5year)</li>
                        <li>*HTML/CSS (5year)</li>
                        など
                    </ul>
                    <ul>
                        <li>Unity (2year)</li>
                        <li>Node.js (2year)</li>
                        <li>Xamarin.Form (1year)</li>
                        <li>.NET Framework (1year)</li>
                        <li>React Native / React (1.5year)</li>
                        <li>Blender (2year)</li>
                        など
                    </ul>
                </div>
            </section>
        </article>
    )
}

export default Profile;