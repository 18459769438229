import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles';

type Props = {
    active?: "Home" | "Profile" | "Works" | "Jobs" | "Other" | "Link" | "Graphics" 
}

const Header: React.FC<Props> = ({ active })=>{

    const [isOpen, setIsOpen] = React.useState(false);

    return(
        <header style={styles.header}>
            <a href="/" style={styles.logoText}>🍐</a>
            <nav style={styles.nav}>
                <div style={styles.navItem}>
                    <Link to="/profile" style={active == "Profile" ? {fontWeight: "bold"} : undefined}>Profile</Link>
                </div>
                <div style={styles.navItem}>
                    <Link to="/works" style={active == "Works" ? {fontWeight: "bold"} : undefined}>Works</Link>
                </div>
                <div style={styles.navItem}>
                    <Link to="/jobs" style={active == "Jobs" ? {fontWeight: "bold"} : undefined}>Jobs</Link>
                </div>
                <div style={styles.navItem}>
                    <Link to="/other" style={active == "Other" ? {fontWeight: "bold"} : undefined}>Other</Link>
                </div>
                <div style={styles.navItem}>
                    <Link to="/link" style={active == "Link" ? {fontWeight: "bold"} : undefined}>Link</Link>   
                </div>
            </nav>
        </header>
    )
}

export default Header;