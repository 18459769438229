import React from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import ItemCell from '../../component/ItemCell/ItemCell';
import DetailWindow, { DetailWindowRef } from '../../module/DetailWindow';
import Header from '../../module/Header';
import SPHeader from '../../module/SPHeader';
import PlatformService from '../../service/PlatformService';
import YounashiService from '../../service/YounashiService';
import styles from './styles';

type Props = {} & RouteComponentProps<{ type: string }>;

const Others: React.FC<Props> = (props) => {

    const { isSP } = PlatformService();

    return(
        <article className="content" style={styles.container}>
            {isSP() ? <SPHeader /> : <Header active="Other"/> }
            <Helmet>
                <title>Other | YounashiPortfolio</title>
            </Helmet>
            <section style={styles.item}>
                <p style={styles.key}>3DCG</p>
                <a href="https://potf.younaship.com/?category=graphics">http://potf.younaship.com/?category=graphics</a>
            </section>
            <section style={styles.item}>
                <p style={styles.key}>Library</p>
                <a href="http://my.younaship.com/?tag=other">http://my.younaship.com/?tag=other</a>
            </section>
            <section style={styles.item}>
                <p style={styles.key}>IoT / Device</p>
                <a href="http://my.younaship.com/?tag=device">http://my.younaship.com/?tag=device</a>
            </section>
        </article>
    )
}

export default Others;