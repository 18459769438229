import React from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import ItemCell from '../../component/ItemCell/ItemCell';
import DetailWindow, { DetailWindowRef } from '../../module/DetailWindow';
import Header from '../../module/Header';
import SPHeader from '../../module/SPHeader';
import PlatformService from '../../service/PlatformService';
import YounashiService from '../../service/YounashiService';
import styles from './styles';

type Props = {} & RouteComponentProps;

const Links: React.FC<Props> = (props) => {

    const { getData } = YounashiService();
    const { isSP } = PlatformService();

    const item = getData().filter((i)=>i.type=="link");

    return(
        <article className="content" style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
            {isSP() ? <SPHeader /> : <Header active="Link"/> }
            <Helmet>
                <title>Links | YounashiPortfolio</title>
            </Helmet>
            <section style={styles.container}>
                <p style={styles.title}>リンク</p>
                <section style={styles.iconLinkContainer}>
                    {item.map((i)=>(
                        <a href={i.url} style={styles.item}>
                            <img src={i.icon} style={styles.icon}/>
                            <p style={styles.linkName}>{i.name}</p>
                        </a>
                    ))}
                </section>
                <section style={styles.otherContainer}>
                    <p style={styles.otherTitle}>その他のリンク</p>
                    <p style={styles.otherSummary}>以前使っていたサイト等・保管庫</p>

                    <ul>
                        <li>
                            <a href="https://potf.younaship.com/" style={styles.otherLink}>ポートフォリオ (2020-)</a>
                        </li>
                        <li>
                            <a href="https://my.younaship.com/" style={styles.otherLink}>ポートフォリオ (2018-20)</a>
                        </li>
                    </ul>

                </section>
            </section>
        </article>
    )
}

export default Links;